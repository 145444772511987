import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SERVER_CONFIG } from '@core/configs';
import { IInvite, IPreRiskAssessment } from '@shared/interfaces';

@Injectable({
    providedIn: 'root'
})

export class InvitesProvider {
    constructor(
        private http: HttpClient
    ) {

    }

    public getInviteByAccountId(userAccountId: string) {
        const url = `${SERVER_CONFIG.processorHostUrl}/invites/${userAccountId}`;
        return this.http.get<IInvite>(url);
    }

    public getPreRiskAssessmentById(preRiskAssessmentId: string) {
        const url = `${SERVER_CONFIG.processorHostUrl}/pre-risk-assessments/${preRiskAssessmentId}`;
        return this.http.get<IPreRiskAssessment>(url);
    }
}
