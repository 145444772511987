<div class="circular-graph-container" #circularGraphRef>
    <span *ngIf="endPercent || preSetScore" class="label score">
        {{ formatPercent(endPercent || preSetScore / 100) }}
    </span>
    <span class="small-label score">
        {{ scoreText }}
    </span>
    <span class="small-label end">
        {{ endText }}
    </span>
</div>


