import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import SRPSessionFactory from 'thinbus-srp/client.js';

import { AuthProvider } from '@api/providers';
import { NotificationService } from '../notification';
import { SRP_CONFIG } from '../configs';
// import { LocalStorageService } from './local-storage.service';
import { CryptoService } from './crypto.service';
import { UserRole } from '../constants';

const SRPSession = SRPSessionFactory(SRP_CONFIG.N, SRP_CONFIG.g, SRP_CONFIG.k);

interface IDecodedToken {
    roles: UserRole[]
}

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    private accessToken: string;

    constructor(
        private authProvider: AuthProvider,
        // private localStorageService: LocalStorageService,
        private cryptoService: CryptoService,
        private notificationService: NotificationService,
    ) {

    }

    public login(email: string, password: string) {
        const srpSession = new SRPSession();

        return this.authProvider.srpChallenge(email).pipe(
            mergeMap(({ salt, B }) => {
                srpSession.step1(email, password);

                const credentials = srpSession.step2(salt, B);
                credentials.email = email;

                return this.authProvider.srpAuth(credentials).pipe(
                    catchError(error => {
                        this.notificationService.showErrorMessage('Mailadresse oder Passwort unbekannt.');
                        return throwError(error);
                    })
                );
            }),
            tap(({ M2, accessToken, /* refreshToken */ }) => {
                srpSession.step3(M2);

                this.accessToken = accessToken;
                // this.localStorageService.save('accessToken', accessToken);
                // this.localStorageService.save('refreshToken', refreshToken);
            })
        );
    }

    public getAccessToken(): string {
        return this.accessToken;
        // return this.localStorageService.get('accessToken');
    }

    public isAuthenticated() {
        return !!this.getAccessToken();
    }

    public getUserRole() {
        if (this.isUserHasRole(UserRole.Broker)) {
            return UserRole.Broker;
        }

        if (this.isUserHasRole(UserRole.FitnessExpert)) {
            return UserRole.FitnessExpert;
        }

        return UserRole.User;
    }

    public isUserHasRoleSales() {
        return this.isUserHasRole(UserRole.Sales);
    }

    public isUserHasRole(role: UserRole) {
        const accessToken = this.getAccessToken();
        const decodedToken = this.cryptoService.parseJwt(accessToken) as IDecodedToken;

        return decodedToken.roles.includes(role);
    }
}
