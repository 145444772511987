import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private authService: AuthService) {
    }

    public canActivate(_, state: RouterStateSnapshot): boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }

    public canActivateChild(_, state: RouterStateSnapshot): boolean {
        return this.canActivate(_, state);
    }
}
