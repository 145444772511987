<div class="page-content-wrapper">
   <img 
        class="page-image"
        src="https://aimo-terraform-email-assets.s3.eu-central-1.amazonaws.com/aimo_hero_image.jpg"
        height="318"
    />
    <div class="page-info">
        <div class="info-title">Es ist ein Fehler aufgetreten</div>
        <p class="info-text">
            Etwas ist schief gegangen und der Vorgang konnte nicht abgeschlossen werden. Bitte versuche es noch einmal oder kontaktiere uns unter <a href="mailto:support@aimo-fit.com">support@aimo-fit.com</a>, damit wir dir helfen können.
        </p>
    </div>
</div>