import { Component, Input } from '@angular/core';

@Component({
    selector: 'aimo-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})

export class LoaderComponent {
    @Input() darkMode: boolean;
    @Input() showInCenter: boolean;
}
