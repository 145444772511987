import { Injectable } from '@angular/core';

import { IStorage } from '@shared/interfaces';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService implements IStorage {
    public save(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public get(key: string): any | null {
        const data = localStorage.getItem(key);

        try {
            return JSON.parse(data);
        } catch (ex) {
            return data;
        }
    }

    public delete(key: string) {
        localStorage.removeItem(key);
    }

    public clear() {
        localStorage.clear();
    }
}
