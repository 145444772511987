import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SERVER_CONFIG } from '@core/configs';
import { IInsurance } from '@shared/interfaces';
import { IPageParams, IPageResponse } from '../common/interfaces';
import { buildQueryParams } from '../common/helpers';
import { DocumentAdapter } from './documents.provider';

@Injectable({
    providedIn: 'root'
})

export class InsurancesProvider {
    constructor(
        private http: HttpClient
    ) {

    }

    public getInsurancesForUser({
        userAccountId,
        pageParams = { pageNumber: 1, pageSize: 25 },
        filterParams = {}
    }: {
        userAccountId: string,
        pageParams?: IPageParams,
        filterParams?: { answered?: boolean }
    }) {
        const params = buildQueryParams({
            page: pageParams.pageNumber - 1,
            page_size: pageParams.pageSize,
            ...filterParams
        });

        const url = `${SERVER_CONFIG.processorHostUrl}/recommendations/for-user/${userAccountId}`;

        return this.http.get<IPageResponse<IInsurance>>(url, { params })
            .pipe(
                map<IPageResponse<any>, IPageResponse<IInsurance>>(response => {
                    const adapter = new InsuranceAdapter();

                    return {
                        ...response,
                        results: response.results.map(
                            insurance => adapter.adapt(insurance)
                        )
                    };
                })
            );
    }
}

export class InsuranceAdapter {
    private documentAdapter: DocumentAdapter;

    constructor() {
        this.documentAdapter = new DocumentAdapter();
    }

    public adapt(rawInsurance: any): IInsurance {
        return {
            ...rawInsurance,
            product: {
                ...rawInsurance.product,
                document: this.documentAdapter.adapt(rawInsurance.product.document)
            }
        };
    }
}
