import { NgModule, Optional, SkipSelf } from '@angular/core';

import { EnsureModuleLoadedOnceGuard } from '../ensure-module-loaded-once.guard';
import { SharedModule } from '@shared/shared.module';

import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';


@NgModule({
    imports: [SharedModule],
    providers: [NotificationService],
    declarations: [NotificationComponent],
    entryComponents: [NotificationComponent]
})

export class NotificationModule extends EnsureModuleLoadedOnceGuard {
    constructor(@Optional() @SkipSelf() parentModule: NotificationModule) {
        super(parentModule);
    }
}
