import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class DownloadService {
    public exportCSVFromArray(
        data: any[],
        headers: { field: string, title: string }[],
        fileName = 'AIMO_export'
    ) {
        const csv = this.convertArrayToCSV(data, headers);
        this.downloadCSV(csv, fileName);
    }

    private convertArrayToCSV(
        data: any[],
        headers: { field: string, title: string }[]
    ) {
        const csvRows = [];

        csvRows.push(
            headers.map(({ title }) => title).join(',')
        );

        data.forEach((item) => {
            csvRows.push(
                headers.map(({ field }) => item[field]).join(',')
            );
        });

        return csvRows.join('\n')
    }

    private downloadCSV(csv: string, fileName: string) {
        this.download(csv, 'text/csv', `${fileName}.csv`)
    }

    private download(content: string, contentType: string, fileName: string) {
        const blob = new Blob([content], { type: contentType });
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click()
    }
}