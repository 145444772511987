import {
    Component,
    Input,
    OnChanges,
    AfterViewInit,
    ViewChild,
    ElementRef
} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'aimo-circular-graph',
    templateUrl: './circular-graph.component.html',
    styleUrls: ['./circular-graph.component.scss']
})
export class CircularGraphComponent implements OnChanges, AfterViewInit {
    @Input() preSetScore: number;
    @Input() scoreText = 'Score';
    @Input() startText = 'Immobile';
    @Input() endText = 'Olympic';
    @Input() endPercent = 0;
    @Input() averageScoreText: string;
    @Input() expertMode: boolean;
    @Input() set disableAnimation(value: false) {
        if (value) {
            this.duration = 0;
        }
    }
    @ViewChild('circularGraphRef', { static: true }) private circularGraphRef: ElementRef;

    _fgColor = '#ffffff';
    _bgColor = 'rgba(255,255,255,.2)';
    _textColor = '#fff';

    // alternative styles
    bgColorAlt = '#d7e5f9';
    fgColorAlt = '#3e80de';
    textColorAlt = '#8994A5';
    textColorNumberAlt = '#3C80DE';

    _radius = 67.5;
    _border = 4;
    _padding = 20;

    _boxHeight = 135;
    _boxWeidth = 165;

    // alternative dimensions
    radiusAlt = 49;
    borderAlt = 3;
    paddingAlt = 20;

    boxHeightAlt = 135;
    boxWeidthAlt = 165;

    unapprovedResult = false;

    formatPercent = d3.format('.0%');
    numberText: any = this.formatPercent(0);
    _numberTextDY = '.35em';
    _numberTextFontSize = '2.7rem';
    numberTextFontWeight = 'normal';

    // alternative numberText styles
    numberTextDYAlt = '.4em';
    numberTextFontSizeAlt = '24px';

    startTextObj: any;
    endTextObj: any;
    _endTextDY = '.35em';
    _endTextTransform = 'translate(45, 60)';
    _endTextFontSize = '14px';
    // alternative endText styles
    endTextDYAlt = '-1.5em';
    endTextTransformAlt = 'translate(31, 60)';
    endTextFontSizeAlt = '11px';

    _scoreDY = '.35em';
    _scoreFontSize = '16px';

    _scoreFontWeight = 'normal';

    scoreTextObj: any;

    // alternative score styles
    scoreDYAlt = '.81em';
    scoreFontSizeAlt = '11px';

    _averagescoreFontSize = '14px';
    // alternative average text styles
    averagescoreFontSizeAlt = '7px';

    scalaBeginAngle = -2.35619; // -135deg
    scalaEndAngle = 2.35619; // 135deg
    scoreStartDomain = 0;
    scoreEndDomain = 1;
    duration = 500; // Animation duration
    roundScale = d3
        .scaleLinear()
        .domain([this.scoreStartDomain, this.scoreEndDomain])
        .range([this.scalaBeginAngle, this.scalaEndAngle]);
    foreground: any;
    markerLine: any;
    averageScoreTextObj: any;
    averageScorePercent = 0;
    totalScorePercent: number = this.scoreEndDomain * 2.7;
    quarterCircle = 90;
    averageScoreStartAngle = 135;
    markerLineStartPoint = 53;
    markerLineEndPoint = 38;

    get fgColor(): string {
        if (typeof this.preSetScore === 'number') {
            return this.fgColorAlt;
        }
        return this._fgColor;
    }

    get bgColor(): string {
        if (typeof this.preSetScore === 'number') {
            return this.bgColorAlt;
        }
        return this._bgColor;
    }

    get textColor(): string {
        if (typeof this.preSetScore === 'number') {
            return this.textColorAlt;
        }
        return this._textColor;
    }

    get radius(): number {
        if (typeof this.preSetScore === 'number') {
            return this.radiusAlt;
        }
        return this._radius;
    }

    get border(): number {
        if (typeof this.preSetScore === 'number') {
            return this.borderAlt;
        }
        return this._border;
    }

    get boxHeight(): number {
        if (typeof this.preSetScore === 'number') {
            return this.boxHeightAlt;
        }
        return this._boxHeight;
    }

    get boxWeidth(): number {
        if (typeof this.preSetScore === 'number') {
            return this.boxWeidthAlt;
        }
        return this._boxWeidth;
    }

    get scoreDY(): string {
        if (typeof this.preSetScore === 'number') {
            return this.scoreDYAlt;
        }
        return this._scoreDY;
    }

    get scoreFontSize(): string {
        if (typeof this.preSetScore === 'number') {
            return this.scoreFontSizeAlt;
        }
        return this._scoreFontSize;
    }

    get numberTextDY(): string {
        if (typeof this.preSetScore === 'number') {
            return this.numberTextDYAlt;
        }
        return this._numberTextDY;
    }

    get numberTextFontSize(): string {
        if (typeof this.preSetScore === 'number') {
            return this.numberTextFontSizeAlt;
        }
        return this._numberTextFontSize;
    }

    get endTextDY(): string {
        if (typeof this.preSetScore === 'number') {
            return this.endTextDYAlt;
        }
        return this._endTextDY;
    }

    get endTextTransform(): string {
        if (typeof this.preSetScore === 'number') {
            return this.endTextTransformAlt;
        }
        return this._endTextTransform;
    }

    get endTextFontSize(): string {
        if (typeof this.preSetScore === 'number') {
            return this.endTextFontSizeAlt;
        }
        return this._endTextFontSize;
    }
    get averagescoreFontSize(): string {
        if (typeof this.preSetScore === 'number') {
            return this.averagescoreFontSizeAlt;
        }
        return this._averagescoreFontSize;
    }

    get textColorNumber(): string {
        if (typeof this.preSetScore === 'number') {
            return this.textColorNumberAlt;
        }
        return this._textColor;
    }

    get marker(): any {
        const averageScoreAngle = this.totalScorePercent * this.averageScorePercent;
        const scoreAngle = averageScoreAngle + this.averageScoreStartAngle;

        return {
            x: Math.cos(Math.PI * (scoreAngle / (this.quarterCircle * 2))),
            y: Math.sin(Math.PI * (scoreAngle / (this.quarterCircle * 2))),
            rotation: this.quarterCircle + averageScoreAngle
        };
    }

    ngOnChanges(changes) {
        if (changes && changes.endPercent) {
            this.endPercent = changes.endPercent.currentValue || 0;
            this.updateView();
        }
    }

    /**
     * Updates view
     */
    private updateView() {
        // NOTE : if we don't use timeout it throws an error this.numberText.text is not a function
        setTimeout(() => {
            if (typeof this.preSetScore === 'number') {
                return;
            }

            const xTransformtion = (coordX: number) => coordX * 80;
            const yTransformtion = (coordY: number) => coordY * 80;
            const textAnchor = (averageScore: number) => {
                if (averageScore < 50) {
                    return 'end';
                } else if (averageScore > 50) {
                    return 'start';
                }
                return 'middle';
            };
            const arc = d3
                .arc()
                .cornerRadius(4)
                .innerRadius(this.radius)
                .outerRadius(this.radius - this.border)
                .startAngle(this.scalaBeginAngle);

            const arcTween = newAngle => {
                return d => {
                    const interpolate = d3.interpolate(d.endAngle, newAngle);
                    return t => {
                        d.endAngle = interpolate(t);
                        return arc(d);
                    };
                };
            };

            this.numberText.text(this.formatPercent(this.endPercent));
            this.startTextObj.text(this.startText);
            this.endTextObj.text(this.endText);
            this.scoreTextObj.text(this.scoreText);

            this.foreground
                .transition()
                .duration(this.duration)
                .attrTween('d', arcTween(this.roundScale(this.endPercent)));

            if (this.averageScoreText) {
                this.markerLine.attr(
                    'transform',
                    'translate(' +
                    this.marker.x +
                    ',' +
                    this.marker.y +
                    ') rotate(' +
                    this.marker.rotation +
                    ')'
                );

                this.averageScoreTextObj
                    .attr(
                        'transform',
                        'translate(' +
                        xTransformtion(this.marker.x) +
                        ',' +
                        yTransformtion(this.marker.y) +
                        ')'
                    )
                    .attr('text-anchor', textAnchor(this.averageScorePercent));
            }
        });
    }

    /**
     * Initializes view
     */
    ngAfterViewInit() {
        const parent = d3.select(this.circularGraphRef.nativeElement);
        const self = this;
        const svg = parent
            .append('svg')
            .attr('width', this.boxWeidth)
            .attr('height', this.boxHeight)
            .attr('viewBox', `0 0 ${this.boxWeidth} ${this.boxHeight}`);

        const main = svg
            .append('g')
            .attr(
                'transform',
                'translate(' + self.boxWeidth / 2 + ',' + self.boxHeight / 2 + ')'
            );

        const arc = d3
            .arc()
            .cornerRadius(4)
            .innerRadius(this.radius)
            .outerRadius(this.radius - this.border)
            .startAngle(this.scalaBeginAngle);

        main
            .append('path')
            .datum({ endAngle: this.roundScale(this.scoreEndDomain) })
            .attr('class', 'circle-background')
            .attr('fill', this.bgColor)
            .attr('fill-opacity', 1)
            .attr('stroke-width', 1)
            .attr('d', arc);

        if (typeof this.preSetScore === 'number') {
            this.foreground = main
                .append('path')
                .datum({ endAngle: this.roundScale(this.preSetScore / 100) })
                .attr('class', 'foreground')
                .attr('fill', this.fgColor)
                .attr('fill-opacity', 1)
                .attr('d', arc);
        } else {
            this.foreground = main
                .append('path')
                .datum({ endAngle: this.scalaBeginAngle }) // start at 0%
                .attr('class', 'foreground')
                .attr('fill', this.fgColor)
                .attr('fill-opacity', 1);
        }

        if (
            typeof this.preSetScore === 'number' &&
            this.expertMode &&
            this.preSetScore === 0
        ) {
            this.numberText = main
                .append('line')
                .style('stroke', this.textColor)
                .style('stroke-width', 1.5)
                .attr('x1', -17)
                .attr('y1', 13)
                .attr('x2', 22)
                .attr('y2', 13);
        } else {
            // this.numberText = main
            //     .append('text')
            //     .attr('fill', this.textColorNumber)
            //     .attr('text-anchor', 'middle')
            //     .attr('font-size', this.numberTextFontSize)
            //     .attr('font-weight', this.numberTextFontWeight)
            //     .attr('class', 'number-txt')
            //     .attr('dy', this.numberTextDY)
            //     .text(this.formatPercent(this.endPercent || this.preSetScore / 100));
        }

        this.startTextObj = main
            .append('text')
            .attr('fill', this.textColor)
            .attr('text-anchor', 'middle')
            .attr('font-size', '1.4rem')
            .attr('dy', '.35em')
            .attr('transform', 'translate(-45, 60)')
            .text(this.startText);

        // this.endTextObj = main
        //     .append('text')
        //     .attr('fill', this.textColor)
        //     .attr('text-anchor', 'middle')
        //     .attr('font-size', this.endTextFontSize)
        //     .attr('dy', this.endTextDY)
        //     .attr('transform', this.endTextTransform)
        //     .text(this.endText);

        // this.scoreTextObj = main
        //     .append('text')
        //     .attr('fill', this.textColor)
        //     .attr('text-anchor', 'middle')
        //     .attr('font-size', this.scoreFontSize)
        //     .attr('dy', this.scoreDY)
        //     .attr('transform', 'translate(0, -25)')
        //     .attr('class', 'score-txt')
        //     .text(this.scoreText);

        if (this.averageScoreText) {
            this.markerLine = main
                .append('line')
                .datum({ endAngle: this.scalaBeginAngle })
                .attr('x1', this.markerLineStartPoint)
                .attr('y1', this.markerLineStartPoint)
                .attr('x2', this.markerLineEndPoint)
                .attr('y2', this.markerLineEndPoint)
                .attr('stroke-width', 2)
                .attr('stroke', this.bgColor);

            this.averageScoreTextObj = main
                .append('text')
                .attr('fill', this.textColor)
                .attr('font-size', this.averagescoreFontSize)
                .attr('class', 'average-score-txt')
                .text(this.averageScoreText);
        }
    }
}
