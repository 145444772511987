import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { AuthService } from '../services';
import { Observable } from 'rxjs';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService
    ) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getAccessToken();

        return next.handle(this.addToken(request, token));
    }

    private addToken(request: HttpRequest<any>, token?: string) {
        if (token) {
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }

        return request;
    }
}
