import { HttpParams } from '@angular/common/http';

export function buildQueryParams(data: any): HttpParams {
    return Object.keys(data).reduce((httpParams, paramName) => {
        const paramValue: string | number | boolean | Date = data[paramName];

        if (typeof paramValue !== 'undefined' && paramValue !== null) {
            httpParams = httpParams.append(paramName, paramValue.toString());
        }

        return httpParams;
    }, new HttpParams());
}
