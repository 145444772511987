import { IntensityLevel } from './intensity-level.enum';

export function calculateIntensityLevel(percent: number): IntensityLevel {
    if (percent >= 67) {
        return IntensityLevel.High;
    } else if (66 >= percent && percent >= 34) {
        return IntensityLevel.Medium;
    } else {
        return IntensityLevel.Low;
    }
}
