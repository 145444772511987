import { Injectable } from '@angular/core';
import * as Forge from 'node-forge';

@Injectable({
    providedIn: 'root'
})

export class CryptoService {
    /**
     * Generates a public and private RSA key pair
     */
    public generateNewKeyPair() {
        return Forge.pki.rsa.generateKeyPair();
    }

    /**
     * Converts the public RSA key to PEM representation
     */
    public convertPublicKeyToPem(publicKey: any) {
        return Forge.pki.publicKeyToPem(publicKey);
    }

    /**
     * Converts the private RSA key to PEM representation
     */
    public convertPrivateKeyToPem(privateKey: any) {
        return Forge.pki.privateKeyToPem(privateKey);
    }

    /*
     * Reverts public key from PEM representation
     */
    public revertPublicKeyFromPem(pemPublicKey: string) {
        return Forge.pki.publicKeyFromPem(pemPublicKey);
    }

    /*
     * Reverts private key from PEM representation
     */
    public revertPrivateKeyFromPem(pemPrivateKey: string) {
        return Forge.pki.privateKeyFromPem(pemPrivateKey);
    }

    /**
     * Encrypts the private key with a password and save and return it in PEM representation
     */
    public encryptPrivateKey(privateKey: Forge.pki.PrivateKey, password: string) {
        return Forge.pki.encryptRsaPrivateKey(privateKey, password, {
            legacy: true,
            algorithm: 'aes256'
        });
    }

    /**
     * Decrypts the private key with a password and save and return it as raw private key
     */
    public decryptPrivateKey(
        encPrivateKeyPem: string,
        password: string
    ): Forge.pki.PrivateKey {
        return Forge.pki.decryptRsaPrivateKey(encPrivateKeyPem, password);
    }

    /**
     * Signs data with the private RSA key
     */
    public signWithPrivateKey(privateKey: any, data: string) {
        // sign data with a private key and output DigestInfo DER-encoded bytes
        // (defaults to RSASSA PKCS#1 v1.5)
        const md = Forge.md.sha256.create();
        md.update(data, 'utf8');
        const signedData = privateKey.sign(md);
        return Forge.util.encode64(signedData);
    }

    /**
     * Encrypts data with public key
     */
    async encryptData(data: string, publicKeyPem: string): Promise<string> {
        try {
            const publicKey = this.revertPublicKeyFromPem(publicKeyPem);

            const aesSecret = Forge.random.getBytesSync(32);
            const iv = Forge.random.getBytesSync(32);
            const keySecret = JSON.stringify({ aesSecret, iv });

            const cipher = Forge.cipher.createCipher('AES-CBC', aesSecret);
            cipher.start({ iv });
            const jsonData = JSON.stringify(data);
            cipher.update(Forge.util.createBuffer(jsonData));
            cipher.finish();

            const encrypted = cipher.output;
            const aesEncrypted = publicKey.encrypt(keySecret, 'RSA-OAEP', {
                md: Forge.md.sha256.create()
            });

            return JSON.stringify({ key: aesEncrypted, data: encrypted });
        } catch {
            return null;
        }
    }

    /**
     * Decrypts data with private key
     */
    async decryptData(encryptedData: string, privateKey: Forge.pki.rsa.PrivateKey): Promise<any> {
        try {
            const { key, data } = JSON.parse(encryptedData);
            const keyDecrypted = privateKey.decrypt(key, 'RSA-OAEP', {
                md: Forge.md.sha256.create()
            });
            const { aesSecret, iv } = JSON.parse(keyDecrypted);

            const decipher = Forge.cipher.createDecipher('AES-CBC', aesSecret);
            decipher.start({ iv });
            decipher.update(Forge.util.createBuffer(data));

            const isFinished = decipher.finish();
            let decryptedData = null;

            if (isFinished) {
                try {
                    decryptedData = JSON.parse(decipher.output.data);
                } catch {
                    decryptedData = decipher.output.data;
                }
            }

            return decryptedData;
        } catch {
            return null;
        }
    }

    public parseJwt(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }
}
