import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SERVER_CONFIG } from '@core/configs';

interface IChallengeResult {
    B: string;
    b: string;
    salt: string;
}

interface IAuthResult {
    M2: string;
    m2: string;
    accessToken: string;
    refreshToken: string;
}

@Injectable({
    providedIn: 'root'
})

export class AuthProvider {
    constructor(
        private http: HttpClient
    ) {

    }

    public srpChallenge(email: string) {
        const data = { email };
        const url = `${SERVER_CONFIG.securityHostUrl}/accounts/actions/challenge`;
        return this.http.post<IChallengeResult>(url, data);
    }

    public srpAuth(credentials) {
        const url = `${SERVER_CONFIG.securityHostUrl}/accounts/actions/authenticate`;
        return this.http.post<IAuthResult>(url, credentials);
    }
}
