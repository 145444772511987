import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { SERVER_CONFIG } from '@core/configs';
import { IPromotion, IPromoCode, IPromotionDescription } from '@shared/interfaces';
import { buildQueryParams } from '../common/helpers';
import { IPageParams, IPageResponse } from '../common/interfaces';

interface ICreatePromotionWithMultiUseCodePayload {
    title: string;
    code: string;
    subscriptionId?: string;
    contentGateEnabled: boolean;
    activationLimit: number;
    startAt: string;
    endAt: string;
    teaserTitle?: string;
    description?: IPromotionDescription;
    media?: string;
}

interface ICreatePromotionWithSingleUseCodesPayload {
    title: string;
    codeType: 'Numeric' | 'Alphanumeric';
    codeLength: number;
    codeAmount: number;
    subscriptionId?: string;
    contentGateEnabled: boolean;
    startAt: string;
    endAt: string;
    teaserTitle?: string;
    description?: IPromotionDescription;
    media?: string;
}

interface IUpdatePromotionPayload {
    title: string;
    contentGateEnabled: boolean;
    startAt: string;
    endAt: string;
    teaserTitle: string;
    description: IPromotionDescription;
    media: string;
}

const promoCodeMapper = (rawData: any): IPromoCode  => ({
    code: rawData.code,
    activationLimit: rawData.activationLimit,
});

const promotionMapper = (rawData: any): IPromotion  => ({
    ...rawData,
    contentGateDisabled: !rawData.contentGateEnabled,
    codes: rawData.codes.map(promoCodeMapper),
    startDate: rawData.startAt ? moment(rawData.startAt) : null,
    endDate: rawData.endAt ? moment(rawData.endAt) : null,
    description: Array.isArray(rawData.description) ? rawData.description : [],
    createdAt: rawData.createdAt ? moment(rawData.createdAt) : null,
    updatedAt: rawData.updatedAt ? moment(rawData.updatedAt) : null,
});

@Injectable({
    providedIn: 'root'
})

export class PromotionsProvider {
    constructor(
        private http: HttpClient
    ) {

    }


    public getPromotionsList({
        pageParams = { pageNumber: 1, pageSize: 25 },
        filterParams = {}
    }: {
        pageParams?: IPageParams,
        filterParams?: { active?: boolean }
    }) {
        const url = `${SERVER_CONFIG.securityHostUrl}/promo-codes`;

        const params = buildQueryParams({
            page: pageParams.pageNumber - 1,
            page_size: pageParams.pageSize,
            ...filterParams
        });

        return this.http.get<IPageResponse<any>>(url, { params }).pipe(
            map(({ page, results }) => ({
                page,
                results: results.map(promotionMapper)
            }))
        )
    }

    public getPromotionById(promoCodeId: string) {
        const url = `${SERVER_CONFIG.securityHostUrl}/promo-codes/${promoCodeId}`;

        return this.http.get<IPromotion>(url).pipe(
            map(promotionMapper)
        );
    }

    public createPromotionWithMultiUseCode(payload: ICreatePromotionWithMultiUseCodePayload) {
        const url = `${SERVER_CONFIG.securityHostUrl}/promo-codes`;

        return this.http.post<IPromotion>(url, payload).pipe(
            map(promotionMapper)
        );
    }

    public createPromotionWithSingleUseCodes(payload: ICreatePromotionWithSingleUseCodesPayload) {
        const url = `${SERVER_CONFIG.securityHostUrl}/promo-codes/generate`;

        return this.http.post<IPromotion>(url, payload).pipe(
            map(promotionMapper)
        );
    }

    public updatePromotion(promotionId: string, payload: IUpdatePromotionPayload) {
        const url = `${SERVER_CONFIG.securityHostUrl}/promo-codes/${promotionId}`;

        return this.http.patch<IPromotion>(url, payload);
    }
}
