import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpStatusCode } from '../constants';

@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
    ) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    switch (error.status) {
                        case HttpStatusCode.NotFound: {
                            this.router.navigateByUrl('/not-found');
                        }
                    }

                    return throwError(error);
                })
            );
    }
}
