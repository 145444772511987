import { Component, Input } from '@angular/core';

@Component({
    selector: 'aimo-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    @Input() public type: 'fixed' | 'inline' = 'inline';
}
