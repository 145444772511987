import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SERVER_CONFIG } from '@core/configs';
import { IDocument } from '@shared/interfaces';
import { PrintInstructionType } from '../../documents/common';

@Injectable({
    providedIn: 'root'
})

export class DocumentsProvider {
    constructor(
        private http: HttpClient
    ) {

    }

    public getDocumentById(documentId: string) {
        const url = `${SERVER_CONFIG.processorHostUrl}/documents/${documentId}`;
        return this.http.get(url).pipe(
            map<any, IDocument>(response => {
                const adapter = new DocumentAdapter();
                return adapter.adapt(response);
            })
        );
    }

    public getDocumentAsset(documentId: string, filename: string) {
        const url = `${SERVER_CONFIG.processorHostUrl}/documents/${documentId}/assets/${filename}`;
        return this.http.get(url).pipe(
            map<any, string>(response => response.link)
        );
    }
}

export class DocumentAdapter {
    public adapt({ id, name, printInstructions }): IDocument {
        return {
            id,
            name,
            pages: printInstructions.map(pageInstructions => ({
                ...pageInstructions,
                documentId: id,
                instructions: pageInstructions.instructions.map(instruction => ({
                    documentId: id,
                    ...instruction,
                    type: getCorrectedInstructionType(instruction, id),
                })),
            })),
        };
    }
}

// facade for bed DB types approach, TODO: refactor DB types
function getCorrectedInstructionType(instruction, documentId): PrintInstructionType {
    if (instruction.id === 'weakestLinkImg') {
        return PrintInstructionType.Avatar;
    }

    if (instruction.id === 'scoreResult') {
        return PrintInstructionType.Score;
    }

    if (instruction.id === 'weakLinks') {
        return PrintInstructionType.WeakLinks;
    }

    if (instruction.id === 'sideHeader') {
        return PrintInstructionType.InfoBlock;
    }

    if (instruction.id === 'logo') {
        return PrintInstructionType.Logo;
    }

    if (instruction.coords.tripleDigitCoords) {
        return PrintInstructionType.SpacedString;
    }

    const individualSalesDocumentIds = [
        '519accd7-d2f3-42dd-9ce2-4a30a5469462',
        '9ba6400a-b8a0-4bf3-b05b-a9d01aafc859'
    ];
    if (individualSalesDocumentIds.includes(documentId)) {
        if (instruction.type === PrintInstructionType.Boolean) {
            return PrintInstructionType.BooleanString;
        }

        if (instruction.type === PrintInstructionType.Image) {
            return PrintInstructionType.BooleanImage;
        }
    }

    return instruction.type;
}
