import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { INotificationPayload } from './interfaces';
import { NotificationComponent } from './notification.component';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {
    constructor(
        private snackBar: MatSnackBar
    ) {

    }

    public showSuccessMessage(message: string) {
        this.showMessage(message, 'success');
    }

    public showErrorMessage(message: string) {
        this.showMessage(message, 'error');
    }

    public showMessage(message: string, type: 'success' | 'error' = 'success') {
        const config: MatSnackBarConfig<INotificationPayload> = {
            duration: 3000000,
            data: {
                type,
                message,
            },
            panelClass: ['aimo-notification', type]
        };
        this.snackBar.openFromComponent(NotificationComponent, config);
    }
}
