<div
	class="loader-container"
	[ngClass]="{'centered': showInCenter }"
>
	<div
		[ngClass]="{'dot': true, 'dark-dot': darkMode}"
		id="dot1"
	></div>
	<div
		[ngClass]="{'dot': true, 'dark-dot': darkMode}"
		id="dot2"
	></div>
	<div
		[ngClass]="{'dot': true, 'dark-dot': darkMode}"
		id="dot3"
	></div>
	<div
		[ngClass]="{'dot': true, 'dark-dot': darkMode}"
		id="dot4"
	></div>
</div>
