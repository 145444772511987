import { Component, Input } from '@angular/core';
import { IntensityLevel } from './intensity-level.enum';
import { calculateIntensityLevel } from './helpers';

@Component({
    selector: 'aimo-intensity-marker',
    templateUrl: './intensity-marker.component.html',
    styleUrls: ['./intensity-marker.component.scss']
})
export class IntensityMarkerComponent {
    @Input() public intensity: IntensityLevel;
    @Input() public percent: number;
    public intensityLevel = IntensityLevel;

    public get currentIntensity(): IntensityLevel {
        return this.intensity || calculateIntensityLevel(this.percent);
    }
}
