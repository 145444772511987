import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { INotificationPayload } from './interfaces';

@Component({
    selector: 'aimo-notification',
    templateUrl: 'notification.component.html',
    styleUrls: ['notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotificationComponent {
    constructor(
        private snackBarRef: MatSnackBarRef<NotificationComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: INotificationPayload,
    ) {

    }

    public onClose() {
        this.snackBarRef.dismiss();
    }
}
