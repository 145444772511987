import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AccountProvider } from '@api/providers';
import { IAccount } from '@shared/interfaces';
import { CryptoService } from './crypto.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})

export class AccountService {
    private account$ = new BehaviorSubject<IAccount>(null);

    constructor(
        private accountProvider: AccountProvider,
        private cryptoService: CryptoService,
        private localStorageService: LocalStorageService,
    ) {

    }

    public receiveAccount(password: string) {
        return this.accountProvider.getAccount().pipe(
            tap((account) => {
                this.account$.next(account);

                const privateKey = this.cryptoService.decryptPrivateKey(
                    account.privateKey,
                    password
                );

                this.savePrivateKey(privateKey);
            })
        );
    }

    public getPrivateKey() {
        const privateKeyRem = this.localStorageService.get('privateKey');
        return this.cryptoService.revertPrivateKeyFromPem(privateKeyRem);
    }

    public savePrivateKey(privateKey) {
        const privateKeyPem = this.cryptoService.convertPrivateKeyToPem(privateKey);
        this.localStorageService.save('privateKey', privateKeyPem);
    }
}
