import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SERVER_CONFIG } from '@core/configs';
import { IAccount } from '@shared/interfaces';

@Injectable({
    providedIn: 'root'
})

export class AccountProvider {
    constructor(
        private http: HttpClient
    ) {

    }

    public getAccount() {
        const url = `${SERVER_CONFIG.securityHostUrl}/accounts/my-account`;
        return this.http.get<IAccount>(url);
    }
}
