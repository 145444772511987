export enum PrintInstructionType {
    String = 'string',
    MultilineString = 'multilineString', // extra
    SpacedString = 'spacedString', // extra
    Boolean = 'boolean',
    BooleanSum = 'booleanSum',
    BooleanString = 'booleanString', // extra
    BooleanImage = 'booleanImage', // extra
    Avatar = 'avatar', // extra
    Score = 'score', // extra
    ScoreLabel = 'scoreLabel',
    InfoBlock = 'infoBlock', // extra
    Logo = 'logo', // extra
    Image = 'image',
    WeakLinks = 'weakLinks', // extra
    // unused but exist in DB
    Complex = 'complexNode',
    Custom = 'customNode',
}
