<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44">
	<g [ngSwitch]="currentIntensity">
		<g *ngSwitchCase="intensityLevel.Low">
			<circle
				class="intensity-ring"
				r="7.55"
				cx="50%"
				cy="50%"
				stroke-width="1.5"
			/>
		</g>

		<g *ngSwitchCase="intensityLevel.Medium">
			<circle
				class="intensity-ring"
				r="12"
				cx="50%"
				cy="50%"
				stroke-width="1"
			/>
			<circle
				class="intensity-ring"
				r="7.55"
				cx="50%"
				cy="50%"
				stroke-width="1.5"
			/>
		</g>

		<g *ngSwitchCase="intensityLevel.High">
			<circle
				class="intensity-ring"
				r="15.618"
				cx="50%"
				cy="50%"
				stroke-width="1"
			/>
			<circle
				class="intensity-ring"
				r="12"
				cx="50%"
				cy="50%"
				stroke-width="1.5"
			/>
			<circle
				class="intensity-ring"
				r="7.55"
				cx="50%"
				cy="50%"
				stroke-width="2"
			/>
		</g>

		<g *ngSwitchDefault>
			<circle class="intensity-outer-circle" r="22" cx="50%" cy="50%" />
			<circle class="intensity-inner-circle" r="13" cx="50%" cy="50%" />
		</g>
	</g>
</svg>
