import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SERVER_CONFIG } from '@core/configs';
import { ISubscription } from '@shared/interfaces';

const subscriptionMapper = (rawData: any): ISubscription => ({
    id: rawData.id,
    title: rawData.title,
    productId: rawData.subscriptionId,
})

@Injectable({
    providedIn: 'root'
})

export class SubscriptionsProvider {
    constructor(
        private http: HttpClient,
    ) {

    }

    public getSubscriptionsList() {
        const url = `${SERVER_CONFIG.securityHostUrl}/subscription-registries`;

        return this.http.get<any[]>(url).pipe(
            map(response => response.map(subscriptionMapper))
        );
    }
}