import { Component } from '@angular/core';

@Component({
    selector: 'aimo-not-found-page',
    templateUrl: './not-found.page.html',
    styleUrls: ['./not-found.page.scss'],
})

export class NotFoundPage {

}
