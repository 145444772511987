import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material';

import { LogoComponent } from './components/logo';
import { LoaderComponent } from './components/loader';
import { FooterComponent } from './components/footer';
import { CircularGraphComponent } from './components/circular-graph';
import { IntensityMarkerComponent } from './components/intensity-marker';

import { NotFoundPage } from './pages/not-found';

const sharedModules = [
    MaterialModule,
    CommonModule,
];

const sharedComponents = [
    LogoComponent,
    LoaderComponent,
    FooterComponent,
    CircularGraphComponent,
    IntensityMarkerComponent,
];

const sharedPages = [
    NotFoundPage,
];

@NgModule({
    declarations: [
        ...sharedComponents,
        ...sharedPages,
    ],
    imports: [
        ...sharedModules,
    ],
    exports: [
        ...sharedModules,
        ...sharedComponents,
        ...sharedPages,
    ]
})
export class SharedModule { }
